.invoiceContainer button{
    width: 5rem;
    padding: .5rem;
    font-weight: bold;
    color: #fff;
    background-color: #3498db;
    transition: all.2s ease-in-out;
    border: 1px solid #3498db;
    border-radius: .8rem;
    margin: 0 .5rem;
}


.invoiceContainer button:hover{
    background-color: #fff;
    color: #3498db;

}
.invoiceContainer{
    background-color: #fff;
    color: #000 !important;
    min-width: 100vw;
    min-height: 100vh;
    padding: 5rem;
    font-weight: bold !important;
}

/* CSS styles for the outer table */
.outer-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.outer-table th,
.outer-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
    background-color: transparent;
}

.outer-table th {
    font-weight: bold;
    background-color: transparent;
}

/* CSS styles for the inner table (Reports) */
.inner-table {
    width: 100%;
    border-collapse: collapse;
}

.inner-table th,
.inner-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    background-color: #f9f9f9;
}
th.header,
.inner-table th {
    font-weight: bold;
    background-color: #3498db;
    color: #fff;                              
}

/* CSS styles for the header section */
.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.school-info {
    text-align: right;
}

.school-logo {
    max-width: 100px;
    margin-bottom: 10px;
}

.school-name {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.student-info {
    font-weight: bold;
    margin-bottom: 10px;
}

/* CSS styles for the footer section */
.footer-section {
    text-align: left;
    margin-top: 20px;
}

.total-expenses {
    font-weight: bold;
    margin-bottom: 10px;
}

/* CSS styles for social media and contact info */
.social-media {
    margin-top: 20px;
    text-align: center;
}

.social-media a {
    margin: 0 10px;
    text-decoration: none;
    color: #3498db;
    font-weight: bold;
}

.contact-info {
    font-weight: bold;
    margin: 1rem auto;
    width: 100%;
}
.contact-info p{
    margin: 1rem auto !important;
    position: relative;
    font: 18px !important;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: fit-content;
}
.contact-info p span{
    color: #3498db;
}
.social-media-section{
    display: flex;
    width:100%;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.Phones,
.Links,
.location{
    display: flex;
    width: 30%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.IconDiv{
    width: 15%;
    height: 3.2rem;
    margin:.5rem;
    background-color:white;
    color:#3498db ;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 30px;
}
.icon{
    font-size:20px;
    margin: auto;
}
.footer-section a{
    margin-top:.5rem ;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media print {
    /* Show the tfoot on all pages except the last one */
    tfoot.print-only {
        display: none;
    }
    
    /* Show the tfoot on the last page */
    tfoot.print-last {
        display: table-row-group; /* Display the tfoot on the last printed page */
    }
}