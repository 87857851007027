.studentDetailsContainer{
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: 1rem auto;
    border-radius: .5rem;
    border: 1px solid rgba(128, 128, 128, 0.485);
    align-items: center;
    padding: .2rem 1rem;
}
.studentDetailsContainer p{
    margin: 0;
}